import React, { Fragment } from 'react'
import { Formik, Form, FormikHelpers } from 'formik'
import { BridgeFormValues } from 'Nbee'
import { FormInner } from './FormInner'
import { Button } from '@components/Basic/ButtonNbe'
import { PageEditor } from '@app/layouts/PageEditor'
import { Panel } from '@components/Panel'
import { useTranslation } from 'react-i18next'
import { makeNbeeSteps } from '@features/nbee/utils'
import { validateNbeeForm } from './validation'
import { touchAllSettings } from '@features/nbee/SimpleBridgeBuilderForm/fields/IntegrationSettings/touchAllSettings'
import { trackEvent } from '@app/dataTracking'
import { makeNbeeTrackingParams } from '@app/dataTracking/utils'
import { StatusBarBridge } from '@features/nbee/StatusBarBridge'
import { useHistory } from 'react-router-dom'
import { appRoutes } from '@app/routes'
import { Loader } from '@components/Basic/Loader'
import { isInIframe } from '@app/utils/isInFrame'
import { ApiUserModuleItem, ApiUser } from 'BackendApi'
import { useEmailReceiptValidation } from '@features/nbee/EmailReceipt/useEmailReceiptValidation'

interface SimpleBridgeBuilderFormProps {
  initialValues: BridgeFormValues
  onSubmit: (
    formValues: BridgeFormValues,
    formikHelpers: FormikHelpers<BridgeFormValues>
  ) => void
  isSavingApi?: boolean
  emailReceiptUserModule?: ApiUserModuleItem
  userData?: ApiUser
}

export const SimpleBridgeBuilderForm: React.FC<SimpleBridgeBuilderFormProps> =
  ({
    initialValues,
    onSubmit,
    isSavingApi,
    emailReceiptUserModule,
    userData,
  }) => {
    const { t } = useTranslation()
    const steps = makeNbeeSteps(0)
    const history = useHistory()
    const isEmbedded = isInIframe()

    return (
      <Formik
        initialValues={initialValues}
        validate={validateNbeeForm}
        enableReinitialize={true}
        validateOnMount
        onSubmit={(values, formikHelpers) => {
          trackEvent({
            eventName: 'NextClicked',
            feature: 'NBEE',
            step: 'Apps',
            params: {
              ...makeNbeeTrackingParams(values),
            },
            sendEventToIntercom: Boolean(values.source.appId === 370), // only send to intercom if source is Facebook Lead Ads
          })
          if (values.ui?.isBridgeEnabled) {
            history.push(appRoutes.nbeeStep2.makeUrl(`${values.ui?.bridgeId}`))
          } else {
            onSubmit(values, formikHelpers)
          }
        }}
      >
        {(formikProps) => {
          // here we handle all cases where button is disabled
          const validationErrors = useEmailReceiptValidation(formikProps.values)
          const isEmailReceiptValid = !emailReceiptUserModule?.enabled
            ? true
            : Object.values(validationErrors).every((value) => value === null)

          const sourceHasApiError =
            formikProps.values.ui?.source?.hasSettingsApiError

          const destinationHasApiError =
            formikProps.values.ui?.destination?.hasSettingsApiError

          const cannotSubmit =
            formikProps.isSubmitting ||
            !formikProps.isValid ||
            sourceHasApiError ||
            destinationHasApiError ||
            isSavingApi ||
            !isEmailReceiptValid

          // when this is loaded inside an iframe, we show a progress bar in the <Panel> component to replace the regular step bar
          // we simulate the progress when both apps have been selected
          const embeddablePercentage =
            formikProps.values.source.appId &&
            formikProps.values.destination.appId
              ? 33
              : 1

          return (
            <Fragment>
              <PageEditor
                formikContext={formikProps}
                steps={steps}
                activeStep={0}
                infoBar={
                  formikProps.values.ui?.bridgeId ? (
                    <StatusBarBridge
                      bridgeId={formikProps.values.ui.bridgeId}
                      isVisible={
                        !(
                          initialValues?.manualStatus === 1 ||
                          initialValues?.manualStatus === 'new'
                        ) // checks if the bridge is in status "new"; in that case we will NOT want to display the edit bar.
                      }
                    />
                  ) : null
                }
                topbarValue={formikProps.values.name}
                topbarError={formikProps.errors.name}
                onTopbarValueChange={
                  // we don't want user to edit name while bridge is saving or is in readOnlyMode
                  isSavingApi || formikProps.values.ui?.isBridgeEnabled
                    ? undefined
                    : (newBridgeName) => {
                        formikProps.setFieldValue('name', newBridgeName)
                      }
                }
                hasTopBar={!isEmbedded}
                backlink={{
                  url: '/bridges',
                  label: t('nbee.bridgeBuilder.exitNbee'),
                }}
              >
                <Form>
                  <Panel
                    progressBarPercentage={
                      isEmbedded ? embeddablePercentage : undefined
                    }
                    body={
                      <>
                        {isSavingApi && (
                          <Loader
                            $active
                            $dimmer
                            $size={'large'}
                            $text={t(
                              'nbee.bridgeBuilder.step1SetupLoadingMessage'
                            )}
                          />
                        )}
                        <FormInner
                          emailReceiptUserModule={emailReceiptUserModule}
                          userData={userData}
                        />
                      </>
                    }
                    footer={{
                      left: null,
                      right: (
                        <span
                          onClick={(e) => {
                            // small workaround to show validation error messages since UX wants to keep the button disabled
                            // and if user does not click on submit not yet touched fields are not set to touched
                            touchAllSettings(formikProps)
                          }}
                        >
                          <Button
                            type={'submit'}
                            $variant={'primary'}
                            disabled={cannotSubmit}
                          >
                            {t('common.continue')}
                          </Button>
                        </span>
                      ),
                    }}
                  />
                </Form>
              </PageEditor>
            </Fragment>
          )
        }}
      </Formik>
    )
  }
