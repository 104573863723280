import React, { useState, useEffect } from 'react'
import { useFetchIntegrationsByAppId } from '@app/api/getIntegrationsByAppId'
import {
  InputSmartSelect,
  SelectValue,
} from '@components/Form/InputSmartSelect'
import { Skeleton } from '@components/Basic/Skeleton'
import { useFetchAllApps } from '@app/api/getAllApps'
import { Trans, useTranslation } from 'react-i18next'
import {
  AppConfigurationType,
  PartialConfiguredApp,
  BridgeFormValues,
} from 'Nbee'
import { useField, useFormikContext } from 'formik'
import { ConnectedPopupWindow } from '@components/ConnectedPopupWindow'
import { appRoutes } from '@app/routes'
import { PopupInstanceDetector } from '@features/nbee/SimpleBridgeBuilderForm/fields/IntegrationSelector/styled'
import { TinyTextualButton } from '@components/Basic/TinyTextualButton'
import { MdEdit } from 'react-icons/md'
import { useTriggerSettingsUpdate } from '@features/nbee/SimpleBridgeBuilderForm/fields/IntegrationSettings/useTriggerSettingsUpdate'
import { InputFeedback } from '@components/Basic/InputFeedback'
import { Loader } from '@components/Basic/Loader'
import {
  getPopupHeight,
  getPopupWeight,
} from '@components/ConnectedPopupWindow/utils'
import { usePostNewIntegration } from '@app/api/postNewIntegration'
import { ApiSaveIntegrationRequestBody } from 'BackendApi'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@app/index'
import { HandleSignupCognitoProps } from '@app/services/apiAuthClient/utils'
import { Auth } from 'aws-amplify'

interface Props {
  appId: number
  parentAppId?: number | '' | null
  type: AppConfigurationType
  disabled?: boolean
  isTesting?: boolean
  onTestIntegrationRequest: () => void
}

export const IntegrationSelector: React.FC<Props> = ({
  appId,
  type,
  parentAppId,
  disabled,
  isTesting,
  onTestIntegrationRequest,
}) => {
  const [currentId, setCurrentId] = useState<number>(0)

  useEffect(() => {
    if (parentAppId === '') {
      setCurrentId(appId)
    } else if (typeof parentAppId === 'number') {
      setCurrentId(parentAppId)
    }
  }, [parentAppId, appId])

  const {
    isLoading,
    data,
    refetch: refetchIntegrationsByAppId,
  } = useFetchIntegrationsByAppId(currentId)

  const dispatch = useDispatch()
  const selectedIdState = useSelector(
    (state: RootState) => state.application.integrationId
  )
  const { values, setFieldValue } = useFormikContext<BridgeFormValues>()

  // integrationId field
  const [integrationIdField, integrationIdMeta, integrationIdHelpers] =
    useField<PartialConfiguredApp['integrationId']>(`${type}.integrationId`)
  const selectedIntegrationId = integrationIdField.value

  const { refetchSettings } = useTriggerSettingsUpdate(type)

  // getting app logo from cached react-query hook
  const { data: allApps } = useFetchAllApps()

  const { mutateAsync: saveIntegrationAsync, isLoading: isSavingIntegration } =
    usePostNewIntegration()

  const selectedApp = (allApps || []).find((app) => app.id === appId)
  const selectedAppLogoUri = selectedApp?.logoUriSmall
  const selectedAppAuthType = selectedApp?.authType
  const selectedAppId = selectedApp?.id

  const initialValues: SelectValue[] = (data || []).map((o) => ({
    label: o.name,
    value: `${o.id}`,
    logoUri: selectedAppLogoUri,
  }))

  // auth credentials popup
  const [popupDestinationUrl, setPopupDestinationUrl] = useState<string | null>(
    null
  )
  const [popupInstace, setPopupInstace] = useState<Window | null>(null)
  const popupIsOpen = Boolean(popupInstace && !popupInstace.closed)

  const [integrationDataFromPopup, setIntegrationDataFromPopup] = useState<{
    id: number
    name: string
  }>()

  // if is an edit we append to popup url the integration id as query string
  // we also need to check if an edit from v1 or is from v2 in that case has a bridgeId
  const bridgeId = values.ui?.bridgeId
  const onConnectNewAccountRequest = async (integrationIdToEdit?: number) => {
    setIntegrationDataFromPopup(undefined)
    let newIntegrationId: number | undefined

    // special case for new integrations of type listener
    // we need to create a new integration to allow BE to create the listener
    // name and credentials can be left empty
    if (
      selectedAppAuthType === 'listener' &&
      selectedAppId === values?.source.appId &&
      !integrationIdToEdit
    ) {
      const requestBody: ApiSaveIntegrationRequestBody = {
        name: '',
        appId: Number(appId),
        credentials: [],
      }
      const newIntegrationResponse = await saveIntegrationAsync({ requestBody })
      newIntegrationId = Number(newIntegrationResponse?.data?.integration?.id)
    }

    const popupUrl =
      selectedAppAuthType === 'listener' &&
      selectedAppId === values?.source.appId && // special case for webhook
      !integrationIdToEdit
        ? appRoutes.incomingDataScreen.makeUrl({
            appId: `${appId}`,
            integrationId: `${newIntegrationId}`,
          })
        : selectedAppAuthType === 'listener' &&
          integrationIdToEdit &&
          selectedAppId === values?.source.appId
        ? appRoutes.incomingDataScreenEdit.makeUrl({
            appId: `${appId}`,
            integrationId: `${integrationIdToEdit}`,
          })
        : integrationIdToEdit && bridgeId
        ? appRoutes.integrationAuthScreenEditWithBridgeId.makeUrl({
            integrationId: `${integrationIdToEdit}`,
            appId: `${currentId}`,
            bridgeId: `${bridgeId}`,
          })
        : integrationIdToEdit
        ? appRoutes.integrationAuthScreenEdit.makeUrl({
            integrationId: `${integrationIdToEdit}`,
            appId: `${currentId}`,
          })
        : appRoutes.integrationAuthScreen.makeUrl({
            appId: `${currentId}`,
          })
    setPopupDestinationUrl(popupUrl)
  }

  useEffect(() => {
    if (selectedIdState !== null && selectedIdState === selectedIntegrationId) {
      onConnectNewAccountRequest(selectedIntegrationId)

      dispatch({ type: 'RESET_TRIGGER_CONNECT_ACCOUNT_POPUP' })
    }
  }, [selectedIdState])

  const defaultValue = integrationDataFromPopup
    ? {
        label: integrationDataFromPopup.name,
        value: integrationDataFromPopup.id,
        logoUri: selectedAppLogoUri,
      }
    : selectedIntegrationId
    ? initialValues.find((o) => o.value === `${selectedIntegrationId}`)
    : undefined

  useEffect(() => {
    setFieldValue(
      `ui.${type}.selectedIntegrationName`,
      defaultValue?.label || ''
    )
  }, [defaultValue?.label])

  const errorMessage = integrationIdMeta.error
  const isTouched = integrationIdMeta.touched
  const hasIntegrationApiError =
    values.ui && values.ui[type] && values.ui[type]?.hasIntegrationApiError
  const isLoadingApi =
    (values.ui && values.ui[type] && values.ui[type]!.isLoadingApi) ||
    isTesting ||
    isSavingIntegration

  const [authData, setAuthData] = useState<HandleSignupCognitoProps | null>(
    null
  )

  // Set Auth Data on mount
  useEffect(() => {
    console.log('isIframe', window.self !== window.top)
    Auth.currentAuthenticatedUser()
      .then((authData_) => {
        setAuthData({
          username: authData_.username || '',
          idToken: authData_.signInUserSession.idToken.jwtToken,
          accessToken: authData_.signInUserSession.accessToken.jwtToken,
          refreshToken: authData_.signInUserSession.refreshToken.token,
          isIframe: window.self !== window.top,
        })
      })
      .catch((e) => {
        console.info('No auth state:', e)
      })
  }, [])

  return (
    <div>
      {isLoading && !defaultValue ? (
        <Skeleton forElement={'smart-select'} />
      ) : (
        <div style={{ position: 'relative' }}>
          {isLoading || isLoadingApi ? <Loader $active $dimmer /> : null}
          <InputSmartSelect
            placeholder={`Connect ${selectedApp?.name}`}
            initialValues={initialValues}
            defaultValue={defaultValue}
            key={defaultValue?.label}
            isLoading={popupIsOpen}
            isDisabled={isLoadingApi || values.ui?.isBridgeEnabled}
            isClearable={false}
            onSelect={(selectedValue) => {
              if (Array.isArray(selectedValue)) {
                return
              }
              const newValue = selectedValue
                ? parseInt(`${selectedValue.value}`, 10)
                : undefined
              integrationIdHelpers.setValue(newValue)
            }}
            addActionClick={{
              label: 'Create a new integration',
              onClick: () => onConnectNewAccountRequest(),
            }}
            onBlur={() => {
              integrationIdHelpers.setTouched(true)
            }}
            noOptionsMessageText={' '}
            $status={
              isTouched && errorMessage
                ? {
                    error: errorMessage,
                  }
                : undefined
            }
          />
        </div>
      )}

      {selectedIntegrationId && !popupIsOpen && !values.ui?.isBridgeEnabled ? (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gap: '1rem',

            // hiding content while loading but keeping height to prevent content jumping
            opacity: isLoadingApi ? 0 : 1,
            pointerEvents: isLoadingApi ? 'none' : undefined,
          }}
        >
          {hasIntegrationApiError ? (
            <InputFeedback
              $status={{
                error: (
                  <Trans
                    ns={'all'}
                    i18nKey={
                      'nbee.bridgeBuilder.validation.integrationApiError'
                    }
                    components={{
                      span: (
                        <span
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            onConnectNewAccountRequest(selectedIntegrationId)
                          }}
                        />
                      ),
                    }}
                  />
                ),
              }}
            />
          ) : (
            <div />
          )}
          <div style={{ textAlign: 'right' }}>
            <TinyTextualButton
              onClick={() => {
                onConnectNewAccountRequest(selectedIntegrationId)
              }}
            >
              <MdEdit /> edit
            </TinyTextualButton>
          </div>
        </div>
      ) : null}

      {popupIsOpen && (
        <PopupInstanceDetector>
          Account configuration screen is open.
          <span
            onClick={() => popupInstace!.focus()}
            style={{
              fontWeight: 600,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            click here to view
          </span>
        </PopupInstanceDetector>
      )}

      {popupDestinationUrl ? (
        <ConnectedPopupWindow
          initialData={authData?.isIframe ? authData : null} // passing auth data to popup only when we're in an iframe
          urlToOpen={popupDestinationUrl}
          popupName={type}
          widthPx={getPopupWeight()}
          heightPx={getPopupHeight()}
          onPopupOpen={(popup) => {
            setPopupInstace(popup)
          }}
          onPopupClose={(newData) => {
            if (newData) {
              const newIntegrationId = (newData as any).integrationId
              const newIntegrationName = (newData as any).integrationName

              // updating field with the just created integrationId
              if (newIntegrationId && newIntegrationName) {
                setIntegrationDataFromPopup({
                  id: newIntegrationId,
                  name: newIntegrationName,
                })
                onTestIntegrationRequest()
                integrationIdHelpers.setValue(newIntegrationId)
              }

              // we refetch all integrations for selected app to keep the list updated
              refetchIntegrationsByAppId()

              // in case we are edited the selected integration we need to refetch integration settings
              if (
                newIntegrationId &&
                newIntegrationId === selectedIntegrationId
              ) {
                refetchSettings()
              }
            }

            setPopupDestinationUrl(null)
          }}
        />
      ) : null}
    </div>
  )
}
